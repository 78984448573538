import NoMatch from "../page/NoMatch";
import ErrorPage from "../page/ErrorPage";
import About from "../page/About";
import Award from "../page/Award";
import Contact from "../page/Contact";
import Festival from "../page/Festival";
import Main from "../page/Main";
import Press from "../page/Press";
import Tod from "../page/Tod";

const dashboardRoutes = [
  {
    path: "/about",
    name: "Api Map",
    component: About,
    menu: true,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 30,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/award",
    name: "Tenants",
    component: Award,
    menu: true,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/contact",
    name: "Tenant Tasks",
    component: Contact,
    menu: false,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 2,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/festival",
    name: "Tenant Dashboard",
    component: Festival,
    menu: false,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 2,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/tod",
    name: "Tenant Dashboard",
    component: Tod,
    menu: false,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 2,
    sidePanelVisible: true,
    fullScreen: false
  },

  {
    path: "/",
    name: "Controllers",
    component: Main,
    menu: true,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 30,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/press",
    name: "Controllers Task",
    component: Press,
    menu: true,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/404",
    name: "404",
    component: NoMatch,
    menu: false,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 30,
    sidePanelVisible: true,
    fullScreen: true
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
    menu: false,
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: 30,
    sidePanelVisible: true,
    fullScreen: true
  },

];

export default dashboardRoutes;
