import React from 'react'
import { Link } from 'react-router-dom'

const data = require('../../data/festivals.json')

export default function Header() {
    return (
        <header id="header" className="header header-transparent">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">

                    <Link className="navbar-brand" to="/">
                        <img src="logo.png" alt="" style={{ height: "59px" }} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><i className="icon icon-menu"></i></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <Link to="/about">HAKKIMIZDA</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <Link to="#" className="" data-toggle="dropdown">FESTİVALLER <i className="fa fa-angle-down"></i></Link>
                                <ul className="dropdown-menu" role="menu">
                                    {
                                        data.festivals.map((festival, key) =>
                                            <li key={key}><Link to={`/festival?f=${festival.id}`}>{festival.name}</Link></li>
                                        )
                                    }
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/tod">TÖD</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/award">ÖDÜLLER</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/press">BASINDA</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact">İletişim</Link>
                            </li>
                            <li className="header-ticket nav-item">

                                {
                                    //   <Link className="ticket-btn btn" to="/">Topluluk Başvuru</Link>
                                }



                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}
