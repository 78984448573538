import React from 'react'
import {Link} from "react-router-dom";


const data = require('../../data/festivals.json')

export default function FestivalSection() {
    return (
        <section className="ts-blog section-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="section-title">Festivallerimiz
                        </h2>
                    </div>
                </div>
                <div className="row">
                    {
                        data.festivals.map((festival, key) =>
                            <div key={key} className='col-lg-4'><div className='post'>
                                <div className='post-media post-image'>
                                    <Link to={`/festival?f=${festival.id}`}><img src={`images/festivals/${festival.id}/logo.png`} className='img-fluid w-100' alt='' /></Link>
                                </div>
                                <div className='post-body'>
                                    <div className='entry-header'>
                                        <h2 className='entry-title'>
                                            <Link to={`/festival?f=${festival.id}`}>{festival.name}</Link>
                                        </h2>
                                    </div>
                                    <div className='post-footer'>
                                        <Link to={`/festival?f=${festival.id}`} className='btn-link'>Festival detayı... <i className='icon icon-arrow-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="speaker-shap">
                <img className="shap2" src="images/shap/news_memphis2.png" alt="" />
                <img className="shap1" src="images/shap/news_memphis1.png" alt="" />
            </div>
        </section>
    )
}
