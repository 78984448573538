import React from 'react'
import { Link } from 'react-router-dom';
const data = require('../../data/show2024.json')

export default function Program2024() {

    const aciklamaYap = (aciklama) => {
        const liste = aciklama.split("*");
        return <div>
            {liste.map((list) =>
                <div className='pb-2'>{list}</div>
            )}
        </div>
    }



    const programLink=()=>{
        return (
            <div className="col-lg-12">
                <h2 className="section-title text-danger">
                    FESTİVAL PROGRAMI
                </h2>
                {
                   // <h3 className='w-100 text-center pb-4'>
                      //  Programı pdf olarak indirmek için <a className="text-danger w-100" target='_blank'
                     //                                        href="program_2024.pdf">tıklayınız.
                     //   <img src={`assets/pdf_icon.png`} className='img-fluid' style={{width: "60px"}} alt=''/></a></h3>
                }


                <h3 className='w-100 text-center pb-4'>
                    Bilet almak için <a className="text-danger w-100" target='_blank'
                                        href="https://biletinial.com/tr-tr/etkinlikleri/16ethos-ankara-uluslararasi-tiyatro-festivali">tıklayınız.
                    </a></h3>


            </div>
        )

    }
    return (
        <section className="ts-blog section-bg">
            <div className="container">

                <div className="row">
                    {
                        programLink()
                    }
                </div>
                {
                    data.dates.map((date, key) =>
                        <React.Fragment>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-title">
                                        {date.date}
                                    </h2>   </div>
                            </div>
                            <div className="row">
                                {
                                    data.shows.map((show, key) =>
                                        // <div className='grid-item'><span className='p-2 text-center text-white mt-4'>" + grup + "</span><br/><span className='pl-3 text-center text-white'><b>" + ulke + "</b></span></div>
                                        show.tarih === date.date ?
                                            <div key={key} className={`col-lg-${date.col} wow fadeInUp`} data-wow-duration='0.5s' data-wow-delay='100ms'>
                                                <div className='post'>


                                                    {show.afis && show.afis !== "" ?


                                                        <div className='post-media post-image'>
                                                            <a href={`/festival?f=$2`}><img src={`images/${show.afis}`} className='img-fluid w-100' alt='' /></a>
                                                        </div>

                                                        : null}
                                                    <div className='post-body pt-2 mt-0'>
                                                        <div className='entry-header pt-3'>
                                                            <h1 className='section-title-game text-danger'>
                                                                {show.oyun}
                                                            </h1>
                                                        </div>
                                                        <hr />
                                                        <div className='entry-content'>

                                                            {show.topluluk && show.topluluk !== "" ? <h3 style={{ color: "#3b1d82" }}>{show.topluluk} - {show.sehir}</h3> : null}
                                                            {
                                                                show.gosteriler ?
                                                                    <div key={key} className="row">
                                                                        <div key={key} className="col-lg-6">
                                                                            {show.gosteriler.map((sub, key) =>
                                                                                sub.tur !== "ÖDÜL" ?
                                                                                    <React.Fragment>
                                                                                        {sub.oyun && sub.oyun !== "" ? <h3 style={{ color: "#3b1d82" }}>{sub.oyun}</h3> : null}
                                                                                        {sub.topluluk && sub.topluluk !== "" ? <h4 style={{ color: "black" }}>{sub.topluluk} {sub.sehir}</h4> : null}

                                                                                        {sub.aciklama && sub.aciklama !== "" ? <h5 style={{ color: "black" }}>{aciklamaYap(sub.aciklama)}</h5> : null}

                                                                                        {sub.afis && sub.afis !== "" ? <div className='post-media post-image'>
                                                                                                <a href={`/festival?f=$2`}><img src={`images/${sub.afis}`} className='img-fluid w-100' alt='' /></a>
                                                                                            </div>
                                                                                            : null}
                                                                                        <hr />
                                                                                    </React.Fragment> : null
                                                                            )
                                                                            }
                                                                        </div>
                                                                        <div key={key} className="col-lg-6">
                                                                            <img src="images/16_ethos.jpeg" className='w-100' alt='afis'></img>
                                                                            {
                                                                                show.gosteriler ?
                                                                                    <div key={key} className="row">
                                                                                        <div key={key} className="col-lg-12">
                                                                                            {show.gosteriler.map((sub, key) =>

                                                                                                sub.tur === "ÖDÜL" ?
                                                                                                    <div className='pt-3'>
                                                                                                        {sub.oyun && sub.oyun !== "" ? <h3 style={{ color: "#3b1d82" }}>{sub.oyun}</h3> : null}
                                                                                                        {sub.topluluk && sub.topluluk !== "" ? <h4 style={{ color: "black" }}>{sub.topluluk} {sub.sehir}</h4> : null}
                                                                                                        {sub.tur && sub.tur !== "" ? <h4 style={{ color: "black" }}>{sub.tur}</h4> : null}
                                                                                                        {sub.aciklama && sub.aciklama !== "" ? <h5 style={{ color: "black" }}>{aciklamaYap(sub.aciklama)}</h5> : null}
                                                                                                        {sub.afis && sub.afis !== "" ? <div className='post-media post-image'>
                                                                                                                <a href={`/festival?f=$2`}><img src={`images/2023/${sub.afis}`} className='img-fluid w-100' alt='' /></a>
                                                                                                            </div>
                                                                                                            : null}
                                                                                                    </div> : null
                                                                                            )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                            {show.tur && show.tur !== "" ? <h4 style={{ color: "black" }}>TÜR: {show.tur}</h4> : null}
                                                            {show.yazar && show.yazar !== "" ? <h4 style={{ color: "black" }}>YAZAN: {show.yazar}</h4> : null}
                                                            {show.konusmaci && show.konusmaci !== "" ? <h4 style={{ color: "black" }}>KONUŞMACI: {show.konusmaci}</h4> : null}
                                                            {show.uyarlayan && show.uyarlayan !== "" ? <h4 style={{ color: "black" }}>UYARLAYAN: {show.uyarlayan}</h4> : null}
                                                            {show.yonetmen && show.yonetmen !== "" ? <h4 style={{ color: "black" }}>YÖNETMEN: {show.yonetmen}</h4> : null}
                                                            {show.muzisyen && show.muzisyen !== "" ? <h4 style={{ color: "black" }}>MÜZİSYEN: {show.muzisyen}</h4> : null}
                                                            {show.aciklama && show.aciklama !== "" ? <h5 style={{ color: "black" }}>{aciklamaYap(show.aciklama)}</h5> : null}
                                                            <hr />
                                                            {show.salon && show.salon !== "" ? <h4 style={{ color: "black" }}>{show.salon}</h4> : null}
                                                            {show.saat && show.saat !== "" ? <h4 style={{ color: "black" }}>SAAT: {show.saat}</h4> : null}
                                                            {show.sure && show.sure !== "" ? <h4 style={{ color: "black" }}>SÜRE: {show.sure}</h4> : null}
                                                            {

                                                                show.aciklama !==""?

                                                                    show.bilet && show.bilet !== "" ?

                                                                        show.bilet === "free" ?
                                                                            <h4 className='btn-success p-2' target='_blank'>Biletler 10 Ekim itibariyle {show.salon}'inden ücretsiz olarak temin edilebilir.</h4>
                                                                            : show.bilet === "-" ?
                                                                                <h4 className='btn-success p-2' target='_blank'>Etkinlik ücretsizdir.</h4>
                                                                                :
                                                                                <Link className='btn btn-info w-100' to={show.bilet} target='_blank'>BİLET AL</Link> : null
                                                                    :<h4 className='btn-success p-2' target='_blank'></h4>



                                                            }
                                                            {false && show.video && show.video !== "" ? <h4 style={{ color: "black" }}>
                                                                <video width="100%" controls>
                                                                    <source src={`http://www.ethostiyatrofestivali.org/video/${show.video}`} type="video/mp4" />
                                                                </video>
                                                            </h4> : null}
                                                        </div>
                                                    </div>
                                                </div> </div>
                                            : null
                                    )
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
            <div className="speaker-shap">
                <img className="shap2" src="images/shap/news_memphis2.png" alt="" />
                <img className="shap1" src="images/shap/news_memphis1.png" alt="" />
            </div>
        </section>
    )
}
