import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
   return (
      <React.Fragment>
      <section className="ts-map-direction wow fadeInUp mb-0 pb-0" data-wow-duration="1.5s" data-wow-delay="400ms">
         <div className="footer-area">
            <footer className="ts-footer">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8 mx-auto">
                        <div className="row">
                           <div className="col-lg-12">
                              <h2 className="column-title" style={{ color: "white" }}>KUSAGEM
                              </h2>
                              <h3 style={{ color: "white" }}>Yıldızevler Mahallesi 708. sokak 23/1 Yıldız Çankaya Ankara
                              </h3>
                           </div>
                        </div>



                        <div className="row">
                        <div className="col-lg-12">
                          
                           <h4 style={{ color: "white" }}>Designed by  
                           <Link to="https://genixo.ai" target='_blank'> <img style={{width:"100px", paddingLeft:"20px"}} src="https://genixo.ai/images/logo-02.png" alt="Genixo Bilişim ve Teknoloji"/>
                         </Link>  </h4>
                        </div>
                     </div>



                        <div className="ts-footer-social text-center mb-10">
                           <ul>
                              <li>
                                 <a href="https://www.facebook.com/ETHOSTiyatroFestivali/"><i className="fa fa-facebook"></i></a>
                              </li>
                              <li>
                                 <a href="https://twitter.com/ethos_festival"><i className="fa fa-twitter"></i></a>
                              </li>
                              <li>
                                 <a href="https://www.instagram.com/ethosankara"><i className="fa fa-instagram"></i></a>
                              </li>
                           </ul>
                        </div>
                        <div className="copyright-text text-center">
                           <p>Copyright © 2023 ETHOS. All Rights Reserved.</p>
                        </div>
                     </div>
                  </div>
                  <div className="speaker-shap">
                     <img className="shap1 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="300ms" src="images/shap/Direction_memphis3.png" alt="" />
                     <img className="shap2 wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="400ms" src="images/shap/Direction_memphis2.png" alt="" />
                     <img className="shap4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="600ms" src="images/shap/Direction_memphis1.png" alt="" />
                  </div>
               </div>

            </footer>
            <div className="BackTo">
               <a href="#" className="fa fa-angle-up" aria-hidden="true"></a>
            </div>

         </div>


         </section>
      </React.Fragment>

   )
}
