import React, { useState, useEffect } from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'

const oyunlar = require('../data/oyunlar.json')

export default function Award() {
  const [games, setGames] = useState([])


  useEffect(() => {
    const gameList = oyunlar.filter((game) => game.turu === 7)
    setGames(gameList)
  }, []);



  return (
    <React.Fragment>
      <Header />
      <Banner name="ETHOS ÖDÜL SUNUMLARI" />
      <section id="ts-speakers" className="ts-speakers speaker-classic" style={{ backgroundImage: "url(images/speakers/speaker_bg.png)" }}>
        <div className="container">
          <div className="row">
            {
              games.map((game, key) =>
                <div key={key} className='col-lg-3 col-md-6'>
                  <div className='ts-speaker'>
                    <div className='speaker-img'>
                      <img className='img-fluid' src={`images/${game.foto}`} alt='Ethos Tiyatro Festivali' />
                    </div>
                    <div className='ts-speaker-info'>
                      <h3 className='ts-title'><Link to='#'>{game.oyun}</Link></h3>
                      <p>{game.topluluk}</p>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <div className="speaker-shap">
          <img className="shap1" src="images/shap/home_speaker_memphis1.png" alt="" />
          <img className="shap2" src="images/shap/home_speaker_memphis2.png" alt="" />
          <img className="shap3" src="images/shap/home_speaker_memphis3.png" alt="" />
        </div>

      </section>
      <Footer />





    </React.Fragment>
  )
}
