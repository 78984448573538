import React, { Component, createContext } from "react";
export const appcontext = createContext();
class appProvider extends Component {
  state = {
   
    hasMenu:true
  };


  setHasMenu = (hasMenu) => {
    this.setState({ hasMenu });
  };


  render() {
    return (
      <appcontext.Provider
        value={{
          ...this.state,
          setHasMenu: this.setHasMenu,
        }}
      >
        {this.props.children}
      </appcontext.Provider>
    );
  }
}
export default appProvider;
