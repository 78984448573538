/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import { useLocation } from 'react-router-dom'
import Footer from './components/Footer'
const oyunlar = require('../data/oyunlar.json')
const shows = require('../data/shows.json')
const festivals = require('../data/festivals.json')

export default function Festival() {
  const [games, setGames] = useState([])
  const [name, setName] = useState("")
  const queryParams = new URLSearchParams(window.location.search)
  const selectedFestival = queryParams.get("f")
  const location = useLocation();

  useEffect(() => {
    const gameList = oyunlar.filter((game) => game.turu === 1 && game.yil === parseInt(selectedFestival))
    const festivalList = festivals.festivals.filter((festival) => festival.id === parseInt(selectedFestival))
    if (festivalList.length > 0) {
      setName(festivalList[0].name)

      
    }
    else {
      setName("")
    }

    console.log(gameList)
    console.log(oyunlar)


    setGames(gameList)
  }, [location]);

  const convertHtml = (html) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    )
  }




  const newDedisng = () => {



    return (
      shows.map((show, key) =>

        // <div className='grid-item'><span className='p-2 text-center text-white mt-4'>" + grup + "</span><br/><span className='pl-3 text-center text-white'><b>" + ulke + "</b></span></div>



        <div key={key} className='col-lg-4 wow fadeInUp' data-wow-duration='0.5s' data-wow-delay='100ms'> <div className='post'>

          <div className='post-media post-image'>

            <img src={`images/festivals/2022/${show.afis}`} className='img-fluid' alt='' />
          </div>




          <div className='post-body'>
            <div className='post-meta'>
              <span className='post-author'>

              
                <h4>{show.grup}</h4>
              </span>
            </div>
            <div className='entry-header'>
              <h2 className='entry-title'>
                <a href='#'>{show.oyun}</a>
              </h2>
            </div>
            <div className='entry-content'>

              <p style={{ color: "black" }}>{show.aciklama}</p>
              <hr />  <p>"{show.yer}</p>
            </div>

{
//   <div className='post-footer'>
//   <a href={show.bilet} target='_blank' className='btn btn-succsess'>Bilet Al <i className='icon icon-arrow-right'></i></a>
// </div>


// <div className='post-footer'>
// <a href='#' className='btn-link'>Detay için tıklayınız <i className='icon icon-arrow-right'></i></a>
// </div>

}
           



          </div>
        </div> </div>




      )
    )
  }


  const oldDeding = () => {
    return (
      games.map((game, key) =>
        <div key={key} className='col-lg-4 wow fadeInUp' data-wow-duration='1.5s' data-wow-delay='400ms'>
          <div className='post'>
            <div className='post-media post-image'>

              {
                parseInt(selectedFestival) === 2023 ?
                    <img src={`images/2023/${game.foto}`} className='img-fluid' alt=''/>
                    : <img src={`images/${game.foto}`} className='img-fluid' alt=''/>
              }


            </div>
            <div className='post-body'>
              <div className='entry-header'>
                <h2 className='entry-title'>
                  {game.oyun} <br />
                  <h4>{game.topluluk}</h4>


                </h2>
              </div>
              <div className='entry-content'>
                <p>{convertHtml(game.aciklama)}</p>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }
  return (
    <React.Fragment>
      <Header />
      <Banner name="ETHOS TİYATRO FESTİVALİ" />
      <section className="ts-blog section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="section-title">
                {name}
              </h2>
            </div>
          </div>
          <div className="row">
            {
              2022 === parseInt(selectedFestival)  ?
                newDedisng()
                :
                oldDeding()
            }
          </div>
        </div>
        <div className="speaker-shap">
          <img className="shap2" src="images/shap/news_memphis2.png" alt="" />
          <img className="shap1" src="images/shap/news_memphis1.png" alt="" />
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}
