import React from 'react'

const data = require('../../data/main_data.json')

export default function TheatresSection() {
    return (
        <section className="ts-pricing gradient" style={{ backgroundImage: "url(images/pricing/pricing_img.jpg)" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="section-title white">Sahneler
                        </h2>
                    </div>
                </div>
                <div className="row">



                    {
                        data.theatres.map((theatre, key) =>
                            <div key={key} className="col-lg-4 wow fadeInUp mt-3" data-wow-duration="1.5s" data-wow-delay="400ms">
                                <div className="pricing-item">
                                 
                                    <div className="ts-pricing-box">

                                        <div className="ts-pricing-header">
                                            <h2 className="ts-pricing-name">{theatre.name}</h2>
                                            <p>
                                                {theatre.address}
                                            </p>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>

                        )
                    }

                </div>
            </div>
            <div className="speaker-shap wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="400ms">
                <img className="shap2" src="images/shap/pricing_memphis1.png" alt="" />
            </div>
        </section>
    )
}
