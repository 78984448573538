import React from 'react'

const shows = require('../../data/shows.json')


export default function ProgramSection() {


    const tarihYap = (gun, saati) => {

        let tarih = ""
        let dateTime = Date.parse(gun)
        let day = dateTime.Day + ""

        let year = dateTime.Year + ""

        let gunad = "";

        switch (100) {
            case 1:
                gunad = "Pazartesi"
                break;
            case 2:
                gunad = "Salı"
                break;
            case 3:
                gunad = "Çarşamba"
                break;
            case 4:
                gunad = "Perşembe"
                break;
            case 5:
                gunad = "Cuma"
                break;
            case 6:
                gunad = "Cumartesi"
                break;
            case 7:
                gunad = "Pazar"
                break;
            default:
                gunad = "-"
        }


        tarih = day + " Mart " + year + " " + gunad + " "

        if (saati !== null && saati !== "") {
            let saat = saati.substring(0, 2)
            let dakika = saati.substring(3, 2)

            tarih = tarih + saat + "." + dakika
        }

        return tarih

    }
    return (
        <section className="ts-blog section-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="section-title">
                            <span>Info Update</span>
                            FESTİVAL PROGRAMI
                        </h2>
                    </div>
                </div>
                <div className="row">
                    {
                        shows.map((show, key) =>

                            // <div className='grid-item'><span className='p-2 text-center text-white mt-4'>" + grup + "</span><br/><span className='pl-3 text-center text-white'><b>" + ulke + "</b></span></div>



                            <div key={key} className='col-lg-4 wow fadeInUp' data-wow-duration='0.5s' data-wow-delay='100ms'> <div className='post'>

                                <div className='post-media post-image'>

                                    <img src={`images/festivals/2022/${show.afis}`} className='img-fluid' alt='' />
                                </div>




                                <div className='post-body'>
                                    <div className='post-meta'>
                                        <span className='post-author'>

                                            <h4>{tarihYap(show.gun, show.saat)}</h4>
                                            <hr /> <h4>{show.grup}</h4>
                                        </span>
                                    </div>
                                    <div className='entry-header'>
                                        <h2 className='entry-title'>
                                            <a href='#'>{show.oyun}</a>
                                        </h2>
                                    </div>
                                    <div className='entry-content'>

                                        <p style={{ color: "black" }}>{show.aciklama}</p>
                                        <hr />  <p>"{show.yer}</p>
                                    </div>


                                    <div className='post-footer'>
                                        <a href={show.bilet} target='_blank' className='btn btn-succsess'>Bilet Al <i className='icon icon-arrow-right'></i></a>
                                    </div>

                                    <div className='post-footer'>
                                        <a href='#' className='btn-link'>Detay için tıklayınız <i className='icon icon-arrow-right'></i></a>
                                    </div>


                                </div>
                            </div> </div>




                        )
                    }
                </div>
            </div>
            <div className="speaker-shap">
                <img className="shap2" src="images/shap/news_memphis2.png" alt="" />
                <img className="shap1" src="images/shap/news_memphis1.png" alt="" />
            </div>
        </section>
    )
}
