import React from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import Footer from './components/Footer'

export default function About() {
  return (
    <React.Fragment>
      <Header />
      <Banner name="Hakkımızda" />
      <section className="ts-intro-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="column-title">
                <span>KÜSAGEM</span>
                KÜLTÜR SANAT VE GÖÇ ETKİNLİKLERİ MERKEZİ DERNEĞİ
              </h2>
              <div className="intro-content-text">
                <p>




                Kültür Sanat ve Göç Etkinlikleri Merkezi Derneği,  kısa adı ile KÜSAGEM,  2009 Haziran döneminde Ankara’da kurulmuş bir sivil toplum örgütüdür. Dernek, araştırmacılar, uygulama uzmanları ve sanatçıları bir araya getirerek kültür, sanat ve göç olguları ile ilgili toplum yararına  projeler yapmak, yürütmek ve bu alanlarda çalışma yürüten bilim ve sanat insanlarını desteklemek amacı ile kurulmuştur. 
                <br /><br />KUSAGEM’e onu oluşturan birimler açısından bakıldığında, derneğin köklerinin, 1987’de Yeşim Eyüboğlu ve Serhan Sarıkaya tarafından kurulan ve günümüzde de faaliyetlerini sürdüren Tiyatro Özgün Deneme tiyatro topluluğuna kadar uzandığı görülecektir. KÜSAGEM aynı zamanda, bu günkü konumuyla üretkenlik temeli üzerinde varlık gösteren kültür ve sanat gönüllülerini buluşturan gönüllü bir yapıdır.
<br /><br />Bu gönüllü yapı ile birlikte bir arada olmanın demokratik ve etik kurallarını oluşturma düşünü vurgulayan “ETHOS” sözcüğü ön takı olarak alınmış, proje aidiyeti Yeşim Eyüboğlu’na ait olan   “ETHOS Ankara Uluslararası Tiyatro Festivali” etkinliği 2002 yılından bu yana düzenlenmeye başlanmıştır. 15. Yılını dolduran “ETHOS Ankara Uluslararası Tiyatro Festivali” ile gelenekselleşen ve temalı bir uluslararası tiyatro festivali etkinliği oluşturma yolunda önemli bir mesafe de alınmıştır.  
<br /><br />KÜSAGEM, aynı zamanda kültürel, sanatsal ve toplumsal gelişime katkı sunmak isteyen kurumlar, birlikler ve bireyler ile koordineli çalışmaların yürütüldüğü bir platform olarak da tasarlanmıştır.  Çankaya Belediyesi, Büyükşehir Belediyesi,  büyükelçilikler, Kültür ve Turizm Bakanlığı ve çeşitli kültür kurumları, tiyatro birlikleri ve sivil toplum kuruluşlarının katkıları ile  27 Mart Dünya Tiyatro Günü’nü içine alan ve 10 günlük süreyi kapsayan  bir zaman diliminde  yıllardır yürütülen “ETHOS Ankara Uluslararası Tiyatro Festivali” ; 2019 yılında düzenlenen “Eğitimci Sanatçılar Buluşması" etkinliği  KUSAGEM etkinliklerinin  en belirgin birer örneği durumundadır. KUSAGEM aynı zamanda kentin kültür ve sanat dokusunu zenginleştirmek amacı ile "ETHOS BULUŞMALARI"  gerçekleştirmekte atölye, konser ve gösterilerden oluşan ve yıla yayılan etkinlikleri ile kentin kültürel dokusuna katkı sunmaktadır. 
<br /><br />Toplumun yaratıcı ve üretken yönlerini harekete geçirmek, sanata ilgi ve katılımı artırmak, göç, kadına yönelik şiddet, insan hakları ve hayvan hakları konularında ortaya çıkan kültürel ve sosyal dışlanmaları ortadan kaldırmaya dönük sanat yolu ile eğitim çalışmaları yürütmek, ülkenin kültürel ve sanatsal alt yapısının geliştirilmesi ve zenginleştirilmesine yönelik projeler ortaya koymak, bu amaçlar doğrultusunda farklı kurum ve oluşumlarla yerel, ulusal ve uluslar arası projeler yürütmek, kültürler arası diyaloğu geliştirmek KÜSAGEM’in temel amaçları arasındadır.



           </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="intro-content-img">
                <img src="images/kusagem.jpeg" style={{ width: "100%" }} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}
