import React from 'react'
import Footer from './components/Footer'
import SloganSection from './sections/SloganSection'
import Header from './components/Header'
import HeroSection from './sections/HeroSection'
import FestivalSection from './sections/FestivalSection'
import ProgramSection from './sections/ProgramSection'
import Program2023 from './sections/Program2023'
import TheatresSection from './sections/TheatresSection'
import SponsorSection from './sections/SponsorSection'
import ApplicationForm from './sections/ApplicationForm'
import Program2024 from "./sections/Program2024";
import data from "../data/main_data.json";
import {Link} from "react-router-dom";




export default function Main() {
    return (
        <React.Fragment>

            <Header/>
            <ApplicationForm/>


            <SloganSection/>


            <Program2024/>
            <SloganSection/>
            {
                //   <SponsorSection/>
            }


            <section className="ts-intro-sponsors" style={{backgroundImage: "url(images/sponsors/sponsor_img.jpg)"}}>
                <div className="container">













                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="section-title white">MEDYA DESTEKÇİSİ
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sponsors-logo text-center">

                                        <Link to="#">
                                            <img src="./images/lavarda.png" style={{width: "150px"}} alt=""/></Link>

                            </div>
                        </div>
                    </div>





                </div>
            </section>

            <TheatresSection/>


            <FestivalSection/>

            <Footer/>
        </React.Fragment>
    )
}
