import React from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import Footer from './components/Footer'
export default function Contact() {
  return (
    <React.Fragment>

    <Header />
    <Banner name="İletişim" />


      <section className="ts-intro-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="column-title">
                <span>KÜSAGEM</span>
                KÜLTÜR SANAT VE GÖÇ ETKİNLİKLERİ MERKEZİ DERNEĞİ
              </h2>
              <div className="intro-content-text">

                <h2 className="column-title">
                  TELEFON: +90 506 787 31 50
                  <br /> <br /> <br />
                  ADRES :
                  <br /><br /> Yıldızevler Mahallesi 708. Sokak 23/1 06550 Yıldız Çankaya / Ankara.
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="intro-content-img">
                <img src="2020/sponsor/kusagem.jpeg" style={{width:"100%"}} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </React.Fragment>
  )
}
