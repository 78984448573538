import React, { useState, useEffect } from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'


const oyunlar = require('../data/oyunlar.json')

export default function News() {

  const [games, setGames] = useState([])


  useEffect(() => {
    const gameList = oyunlar.filter((game) => game.turu === 9)
    setGames(gameList)
  }, []);

  const convertHtml = (html) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    )

  }

  return (
    <React.Fragment>


      <Header />
      <Banner name="BASINDA" />


      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row">

            <div className="col-lg-8 col-md-8 col-sm-12 mx-auto">




              {
                games.map((game, key) =>


                  <div key={key} className='post'><div className='post-media post-image'>
                    <Link to='#'><img src={`images/${game.foto}`} className='img-fluid' alt='' /></Link></div>
                    <div className='post-body'>
                      <div className='entry-header'>
                        <h2 className='entry-title'>{game.oyunAd}<br />{game.topluluk}</h2></div>

                      <div className='entry-content'><p>{convertHtml(game.aciklama)}</p></div>

                    </div></div>




                )
              }




            </div>

          </div>

        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}
