import React from 'react'
import { Link } from 'react-router-dom'

const data = require('../../data/main_data.json')

export default function SponsorSection() {
  return (
    <section className="ts-intro-sponsors" style={{backgroundImage: "url(images/sponsors/sponsor_img.jpg)"}}>
        <div className="container">






            <div className="row">
                <div className="col-lg-12">
                    <h2 className="section-title white">DESTEKÇİLERİMİZ
                    </h2>
                    <h2 className="section-title white">RESMİ KURUMLAR
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="sponsors-logo text-center">
                    {
                        data.sponsors.state_agencies.map((sponsor, key)=>
                        <Link key={key} href="#">
                        <img src={sponsor.logo} style={{width:"150px"}} alt=""/></Link>
                        )
                    }
                       
                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col-lg-12">
                    <h2 className="section-title white">SİVİL TOPLUM KURULUŞLARI
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="sponsors-logo text-center">
                    {
                        data.sponsors.ngos.map((sponsor, key)=>
                        <Link key={key} href="#">
                        <img src={sponsor.logo} style={{width:"150px"}} alt=""/></Link>
                        )
                    }
                    </div>
                </div>
            </div>




            <div className="row">
                <div className="col-lg-12">
                    <h2 className="section-title white">FİRMALAR
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="sponsors-logo text-center">
                       
                    {
                        data.sponsors.companies.map((sponsor, key)=>
                        <Link key={key} href="#">
                        <img src={sponsor.logo} style={{width:"150px"}} alt=""/></Link>
                        )
                    }
                    </div>
                </div>
            </div>













            <div className="row">
                <div className="col-lg-12">
                    <h2 className="section-title white">SANAT KURUMLARI
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="sponsors-logo text-center">
                    {
                        data.sponsors.arts.map((sponsor, key)=>
                        <Link key={key} href="#">
                        <img src={sponsor.logo} style={{width:"150px"}} alt=""/></Link>
                        )
                    }
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-lg-12">
                    <h2 className="section-title white">MEDYA KURULUŞLARI
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="sponsors-logo text-center">
                    {
                        data.sponsors.medias.map((sponsor, key)=>
                        <Link key={key} href="#">
                        <img src={sponsor.logo} style={{width:"150px"}} alt=""/></Link>
                        )
                    }
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-lg-12">
                    <h2 className="section-title white">EĞİTİM KURUMLARI
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="sponsors-logo text-center">
                    {
                        data.sponsors.educations.map((sponsor, key)=>
                        <Link key={key} href="#">
                        <img src={sponsor.logo} style={{width:"150px"}} alt=""/></Link>
                        )
                    }
                    </div>
                </div>
            </div>


        </div>
    </section>

  )
}
