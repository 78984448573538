import React from 'react'

export default function Banner(props) {
  return (
    <div id="page-banner-area" className="page-banner-area" style={{ backgroundImage: "url(images/hero_area/banner_bg.jpg)" }}>
    <div className="page-banner-title">
      <div className="text-center">
        <h2>{props.name}</h2>
      </div>
    </div>
  </div>
  )
}
