import React from 'react'

export default function SloganSection() {
    return (
        <section id="ts-experiences" className="ts-experiences">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 no-padding">
                        <div className="exp-img" style={{ backgroundImage: "url(images/cta_img.jpg)" }}>
                            <img className="img-fluid" src="images/cta_img.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 no-padding align-self-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms">
                        <div className="ts-exp-wrap">
                            <div className="ts-exp-content">
                                <h4 style={{color: "white"}}>
                                    <div>

                                        Yüzyıllardır insana insanı anlatan tiyatro sanatının her ürününde aşkın
                                        izlerini, gölgelerini bulmak mümkün. Aşkla tanışanlar, bir şeye aşk ile
                                        bağlananların yolu da sanat ile kesişmektedir. Festivalimizin 16. yılını
                                        doldurmasının, gelenekselleşmesinin temelinde de tiyatro aşkı yatmaktadır.
                                        <br/>
                                        Sözlü dönemlerin, destanlara, oyunlara dek var olan gerçekliğin en belirgin
                                        teması olan ve bir ağacın dalları gibi yurt sevgisinden, doğa sevgisine, yaşam
                                        sevgisinden, özgürlük aşkına kadar çeşitlenen düşünceyle, inançla bütünleşen
                                        aşk; 16. ETHOS Ankara Uluslararası Tiyatro Festivali’nde; oyunlar, atölyeler,
                                        sokak gösterileri ile Ankaralı izleyiciler ile buluşulacak. İyi Seyirler Ankara.
                                    </div>


                                </h4>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
