import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import routes from "./config/route";
import AppContextProvider from "./config/appcontex";
import NoMatch from './page/NoMatch';

const root = ReactDOM.createRoot(document.getElementById('root'));

const compare = (a, b) => {
  if (a.sort < b.sort) {
    return -1;
  }
  if (a.sort > b.sort) {
    return 1;
  }
  return 0;
};

root.render(
  <AppContextProvider>
    <BrowserRouter>
      <main className="content">
        <Routes>
          {routes.sort(compare).map((route, key) => (
            <Route key={key} path={route.path} element={<route.component {...route} />} />
          ))}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </main>
    </BrowserRouter>
  </AppContextProvider>
);
reportWebVitals();
